@font-face {
  font-family: 'Roboto-Black';
  src: url(/static/media/Roboto-Black.301fe70f.ttf);
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url(/static/media/Roboto-BlackItalic.c470ca2b.ttf);
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url(/static/media/Roboto-Bold.9ece5b48.ttf);
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url(/static/media/Roboto-BoldItalic.0be9fa8f.ttf);
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url(/static/media/Roboto-Italic.465d1aff.ttf);
}

@font-face {
  font-family: 'Roboto-Light';
  src: url(/static/media/Roboto-Light.6090d256.ttf);
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url(/static/media/Roboto-LightItalic.2ffc058d.ttf);
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url(/static/media/Roboto-Medium.b2d307df.ttf);
}

@font-face{
  font-family: 'Roboto-MediumItalic';
  src: url(/static/media/Roboto-MediumItalic.cabdb4a1.ttf);
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url(/static/media/Roboto-Regular.f36638c2.ttf);
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url(/static/media/Roboto-Thin.4f0b85f5.ttf);
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url(/static/media/Roboto-ThinItalic.7384da64.ttf);
}

@font-face {
  font-family: 'Display-Black' ;
  src: url(/static/media/sf-ui-display-black-58646a6b80d5a_0.7ea55178.otf);
}

@font-face {
  font-family: 'Display-Bold' ;
  src: url(/static/media/sf-ui-display-bold-58646a511e3d9_0.14df481e.otf);
}

@font-face {
  font-family: 'Display-Heavy' ;
  src: url(/static/media/sf-ui-display-heavy-586470160b9e5_0.4ef2b633.otf);
}

@font-face {
  font-family: 'Display-Light' ;
  src: url(/static/media/sf-ui-display-light-58646b33e0551_0.e77aa338.otf);
}

@font-face {
  font-family: 'Display-Medium';
  src: url(/static/media/sf-ui-display-medium-58646be638f96_0.15591c67.otf);
}

@font-face {
  font-family: 'Display-SemiBold' ;
  src: url(/static/media/sf-ui-display-semibold-58646eddcae92_0.043e3f3a.otf);
}

@font-face {
  font-family: 'Display-Thin' ;
  src: url(/static/media/sf-ui-display-thin-58646e9b26e8b_0.e9d16f8c.otf);
}

@font-face {
  font-family:'Display-UltraLight' ;
  src: url(/static/media/sf-ui-display-ultralight-58646b19bf205_0.aa4a0bb7.otf);
}

@font-face {
  font-family: 'Display-Regular' ;
  src: url("/static/media/SF UI Display Regular.4f453270.ttf");
}


:root{
  --Color-Blanco: #fff;
  --Color-Negro: #000;
  --Color-Red-Primary: #E42B31;
  --Color-Grey: #EEEEEE;
  --Color-Grey-Dark:  #7F7F7F;
  --Color-Grey-Light: #F6F6F6;
  --Color-Green-Aproved: #41B96A;
  --Color-Yellow-Paused: #FFC120;

  --Font-Roboto-Regular: 'Roboto-Regular';
  --Font-Roboto-Thin: 'Roboto-Thin';
  --Font-Roboto-Black: 'Roboto-Bold';
  
  --Font-Display-Regular: 'Display-Regular';
  --Font-Display-UltraLight: 'Display-UltraLight';
  --Font-Display-Thin: 'Display-Thin';
  --Font-Display-SemiBold: 'Display-SemiBold';
  --Font-Display-Medium:'Display-Medium';
  --Font-Display-Light:'Display-Light';
  --Font-Display-Heavy: 'Display-Heavy';
  --Font-Display-Bold:'Display-Bold';
  --Font-Display-Black:'Display-Black'

}
body{
    background: radial-gradient(91.5% 210.93% at -13.44% 132.89%, #F9F9F9 0%, rgba(196, 196, 196, 0) 100%);
    max-width: 100% !important;
}

::-webkit-scrollbar {
    display: none;
}

li{
    list-style: none;
}



.layout-general{
    padding: 0 !important;
    transition: all .25s linear !important;
}

/* RUEDA LOADING ANIMATION */
.Rueda_loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  
}
  
.Rueda_loading div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
  
.Rueda_loading div:nth-child(1) {
    left: 8px;
    background: var(--Color-Red-Primary);
    -webkit-animation: lds-ellipsis1 0.6s infinite;
            animation: lds-ellipsis1 0.6s infinite;
}
  
.Rueda_loading div:nth-child(2) {
    left: 8px;
    background: var(--Color-Negro);
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
}
  
.Rueda_loading div:nth-child(3) {
    left: 32px;
    -webkit-animation: lds-ellipsis2 0.6s infinite;
            animation: lds-ellipsis2 0.6s infinite;
    background: var(--Color-Red-Primary);
}
  
.Rueda_loading div:nth-child(4) {
    left: 56px;
    -webkit-animation: lds-ellipsis3 0.6s infinite;
            animation: lds-ellipsis3 0.6s infinite;
}
  
@-webkit-keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
  
@keyframes lds-ellipsis1 {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
}
  
@-webkit-keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
}
  
@keyframes lds-ellipsis3 {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    100% {
      -webkit-transform: scale(0);
              transform: scale(0);
    }
}
  
@-webkit-keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
}
  
@keyframes lds-ellipsis2 {
    0% {
      -webkit-transform: translate(0, 0);
              transform: translate(0, 0);
    }
    100% {
      -webkit-transform: translate(24px, 0);
              transform: translate(24px, 0);
    }
}

.fade-in {
    transition: opacity 1s ease;
}
  
.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}

.Rueda_InputInicio{
    width: 90%;
    border-radius: 14px;
    padding: 15px;
    border: solid 1px #dbdbdb !important;
    outline: none !important;
    box-shadow: 1px 1px 1px 1px var(--Color-White) !important;
    transition: all .25s linear;
}

.Rueda_InputInicio::-webkit-input-placeholder{
    font-family: var(--Font-Display-Regular);
    font-size: 14px;
}

.Rueda_InputInicio:-ms-input-placeholder{
    font-family: var(--Font-Display-Regular);
    font-size: 14px;
}

.Rueda_InputInicio::placeholder{
    font-family: var(--Font-Display-Regular);
    font-size: 14px;
}

.Rueda_InputInicio:focus-visible{
    -webkit-transform: scale(0.9)!important;
            transform: scale(0.9)!important;
    border:  solid 1px var(--Color-Green-Aproved) !important;
    box-shadow: 1px 1px 1px 1px var(--Color-Grey-Dark) !important;
}

.nav-menu{
    height: 100vh;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    background-color: var(--Color-Negro);
}

li.active a{
    font-family: var(--Font-Display-Regular);
    color: var(--Color-Red-Primary)
}

.navbar-toggle{
    position: absolute !important;
    bottom: 25px !important;
}



input[type="file"] {
    display: none;
}

.modal-dialog {
    max-width: 50% !important;
}

.modal-content{
    border-radius: 2rem !important;
}


input[type="radio"] {
    display: none;
}

input[type="radio"]:checked + label {
    border: 2px solid var(--Color-Green-Aproved);
    border-radius: 10px;
    box-shadow: 0px -1px 10px -4px var(--Color-Green-Aproved);
}

.border-left{
    border-left: 1px solid black;
    padding-left: 30px !important;
}

.border-left-grey{
    border-left: 1px dotted var(--Color-Grey-Dark);
}

.bg-grey{
    background-color: var(--Color-Grey-Light);
    border-radius: 23px;
}

.bg-Darkgrey{
    background-color: var(--Color-Grey-Dark);
}

.tituloColumnaUsuario{
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
    margin-bottom: 0px;
}

.tablaUsuario{
    margin-bottom: 20px;
    border-bottom: 1px solid var(--Color-Grey-Dark);
}

.datoColumnaUsuario{
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
    margin-bottom: 0;
    color: var(--Color-Negro);
}

.sinDatoColumnaUsuario{
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
    margin-bottom: 0;
    color: var(--Color-Red-Primary) !important;
}

.text-green{
    color: var(--Color-Green-Aproved)!important;
}

.text-alert{
    color: var(--Color-Red-Primary) !important;
}

.contentLinkOpcionUsuario{
    padding: 25px 10px;
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);
}

.linkOpcionUsuario{
    -webkit-text-decoration: 1px underline #fff;
            text-decoration: 1px underline #fff;
    color: var(--Color-Negro);
    font-family: var(--Font-Display-Regular);
    transition: all .25s linear;
    background-color: #fff;
   

}

.linkOpcionUsuario:hover{
    -webkit-transform: scale(.9);
            transform: scale(.9);
    color: var(--Color-Blanco);
    -webkit-text-decoration: 1px underline var(--Color-Grey-Dark)!important;
            text-decoration: 1px underline var(--Color-Grey-Dark)!important;
    background-color: #808080;

}

.busqueda-Usuarios{
    margin-top: -52px;
    width: 110%;
    margin-left: -5%;
    padding: 30px !important;
    background-color: var(--Color-Grey-Light);
}

.boton-filtrar{
    background: var(--Color-Blanco);
    color: var(--Color-Red-Primary);
    border: 1px solid var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    padding: 10px;
    width: 70%;
    margin-left: 15%;
    border-radius: 10px;
    transition: all .25s linear;
}

.boton-filtrar:hover{
    border: 1px solid var(--Color-Blanco);
    -webkit-transform: scale(.9);
            transform: scale(.9);
    font-family: var(--Font-Display-Bold);
}

option.placeholder-select{
    font-family: var(--Font-Display-Regular)!important;
    font-size: 14px !important;
    color: var(--Color-Grey-Dark);
}

.page-item{
    border: 1px solid var(--Color-Grey-Dark);
    transition: all .25s linear;

}

.page-item button{
    font-family: var(--Font-Display-Regular)!important;
    color: var(--Color-Grey-Dark);
    font-size: 12px !important;
    padding: 15px;
    transition: all .25s linear;
}

.page-item:hover{
    border: 1px solid #fff;
    background-color: var(--Color-Grey-Dark);
}


