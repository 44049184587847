body{
    background: radial-gradient(91.5% 210.93% at -13.44% 132.89%, #F9F9F9 0%, rgba(196, 196, 196, 0) 100%);
    max-width: 100% !important;
}

::-webkit-scrollbar {
    display: none;
}

li{
    list-style: none;
}



.layout-general{
    padding: 0 !important;
    transition: all .25s linear !important;
}

/* RUEDA LOADING ANIMATION */
.Rueda_loading {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  
}
  
.Rueda_loading div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
  
.Rueda_loading div:nth-child(1) {
    left: 8px;
    background: var(--Color-Red-Primary);
    animation: lds-ellipsis1 0.6s infinite;
}
  
.Rueda_loading div:nth-child(2) {
    left: 8px;
    background: var(--Color-Negro);
    animation: lds-ellipsis2 0.6s infinite;
}
  
.Rueda_loading div:nth-child(3) {
    left: 32px;
    animation: lds-ellipsis2 0.6s infinite;
    background: var(--Color-Red-Primary);
}
  
.Rueda_loading div:nth-child(4) {
    left: 56px;
    animation: lds-ellipsis3 0.6s infinite;
}
  
@keyframes lds-ellipsis1 {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
}
  
@keyframes lds-ellipsis3 {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(0);
    }
}
  
@keyframes lds-ellipsis2 {
    0% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(24px, 0);
    }
}

.fade-in {
    transition: opacity 1s ease;
}
  
.fade-out {
    opacity: 0;
    transition: opacity 1s ease;
}

.Rueda_InputInicio{
    width: 90%;
    border-radius: 14px;
    padding: 15px;
    border: solid 1px #dbdbdb !important;
    outline: none !important;
    box-shadow: 1px 1px 1px 1px var(--Color-White) !important;
    transition: all .25s linear;
}

.Rueda_InputInicio::placeholder{
    font-family: var(--Font-Display-Regular);
    font-size: 14px;
}

.Rueda_InputInicio:focus-visible{
    transform: scale(0.9)!important;
    border:  solid 1px var(--Color-Green-Aproved) !important;
    box-shadow: 1px 1px 1px 1px var(--Color-Grey-Dark) !important;
}

.nav-menu{
    height: 100vh;
    position: sticky;
    top: 0;
    background-color: var(--Color-Negro);
}

li.active a{
    font-family: var(--Font-Display-Regular);
    color: var(--Color-Red-Primary)
}

.navbar-toggle{
    position: absolute !important;
    bottom: 25px !important;
}



input[type="file"] {
    display: none;
}

.modal-dialog {
    max-width: 50% !important;
}

.modal-content{
    border-radius: 2rem !important;
}


input[type="radio"] {
    display: none;
}

input[type="radio"]:checked + label {
    border: 2px solid var(--Color-Green-Aproved);
    border-radius: 10px;
    box-shadow: 0px -1px 10px -4px var(--Color-Green-Aproved);
}

.border-left{
    border-left: 1px solid black;
    padding-left: 30px !important;
}

.border-left-grey{
    border-left: 1px dotted var(--Color-Grey-Dark);
}

.bg-grey{
    background-color: var(--Color-Grey-Light);
    border-radius: 23px;
}

.bg-Darkgrey{
    background-color: var(--Color-Grey-Dark);
}

.tituloColumnaUsuario{
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
    margin-bottom: 0px;
}

.tablaUsuario{
    margin-bottom: 20px;
    border-bottom: 1px solid var(--Color-Grey-Dark);
}

.datoColumnaUsuario{
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
    margin-bottom: 0;
    color: var(--Color-Negro);
}

.sinDatoColumnaUsuario{
    font-family: var(--Font-Display-SemiBold);
    font-size: 12px;
    margin-bottom: 0;
    color: var(--Color-Red-Primary) !important;
}

.text-green{
    color: var(--Color-Green-Aproved)!important;
}

.text-alert{
    color: var(--Color-Red-Primary) !important;
}

.contentLinkOpcionUsuario{
    padding: 25px 10px;
    box-shadow: 0px -1px 30px -4px rgba(127, 127, 127, 0.26);
}

.linkOpcionUsuario{
    text-decoration: 1px underline #fff;
    color: var(--Color-Negro);
    font-family: var(--Font-Display-Regular);
    transition: all .25s linear;
    background-color: #fff;
   

}

.linkOpcionUsuario:hover{
    transform: scale(.9);
    color: var(--Color-Blanco);
    text-decoration: 1px underline var(--Color-Grey-Dark)!important;
    background-color: #808080;

}

.busqueda-Usuarios{
    margin-top: -52px;
    width: 110%;
    margin-left: -5%;
    padding: 30px !important;
    background-color: var(--Color-Grey-Light);
}

.boton-filtrar{
    background: var(--Color-Blanco);
    color: var(--Color-Red-Primary);
    border: 1px solid var(--Color-Red-Primary);
    font-family: var(--Font-Display-Regular);
    padding: 10px;
    width: 70%;
    margin-left: 15%;
    border-radius: 10px;
    transition: all .25s linear;
}

.boton-filtrar:hover{
    border: 1px solid var(--Color-Blanco);
    transform: scale(.9);
    font-family: var(--Font-Display-Bold);
}

option.placeholder-select{
    font-family: var(--Font-Display-Regular)!important;
    font-size: 14px !important;
    color: var(--Color-Grey-Dark);
}

.page-item{
    border: 1px solid var(--Color-Grey-Dark);
    transition: all .25s linear;

}

.page-item button{
    font-family: var(--Font-Display-Regular)!important;
    color: var(--Color-Grey-Dark);
    font-size: 12px !important;
    padding: 15px;
    transition: all .25s linear;
}

.page-item:hover{
    border: 1px solid #fff;
    background-color: var(--Color-Grey-Dark);
}

