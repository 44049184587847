@font-face {
  font-family: 'Roboto-Black';
  src: url('./assets/Fonts/Roboto/Roboto-Black.ttf');
}

@font-face {
  font-family: 'Roboto-BlackItalic';
  src: url('./assets/Fonts/Roboto/Roboto-BlackItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Bold';
  src: url('./assets/Fonts/Roboto/Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto-BoldItalic';
  src: url('./assets/Fonts/Roboto/Roboto-BoldItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Italic';
  src: url('./assets/Fonts/Roboto/Roboto-Italic.ttf');
}

@font-face {
  font-family: 'Roboto-Light';
  src: url('./assets/Fonts/Roboto/Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto-LightItalic';
  src: url('./assets/Fonts/Roboto/Roboto-LightItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Medium';
  src: url('./assets/Fonts/Roboto/Roboto-Medium.ttf');
}

@font-face{
  font-family: 'Roboto-MediumItalic';
  src: url('./assets/Fonts/Roboto/Roboto-MediumItalic.ttf');
}

@font-face {
  font-family: 'Roboto-Regular';
  src: url('./assets/Fonts/Roboto/Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto-Thin';
  src: url('./assets/Fonts/Roboto/Roboto-Thin.ttf');
}

@font-face {
  font-family: 'Roboto-ThinItalic';
  src: url('./assets/Fonts/Roboto/Roboto-ThinItalic.ttf');
}

@font-face {
  font-family: 'Display-Black' ;
  src: url('./assets/Fonts/Display/sf-ui-display-black-58646a6b80d5a_0.otf');
}

@font-face {
  font-family: 'Display-Bold' ;
  src: url('./assets/Fonts/Display/sf-ui-display-bold-58646a511e3d9_0.otf');
}

@font-face {
  font-family: 'Display-Heavy' ;
  src: url('./assets/Fonts/Display/sf-ui-display-heavy-586470160b9e5_0.otf');
}

@font-face {
  font-family: 'Display-Light' ;
  src: url('./assets/Fonts/Display/sf-ui-display-light-58646b33e0551_0.otf');
}

@font-face {
  font-family: 'Display-Medium';
  src: url('./assets/Fonts/Display/sf-ui-display-medium-58646be638f96_0.otf');
}

@font-face {
  font-family: 'Display-SemiBold' ;
  src: url('./assets/Fonts/Display/sf-ui-display-semibold-58646eddcae92_0.otf');
}

@font-face {
  font-family: 'Display-Thin' ;
  src: url('./assets/Fonts/Display/sf-ui-display-thin-58646e9b26e8b_0.otf');
}

@font-face {
  font-family:'Display-UltraLight' ;
  src: url('./assets/Fonts/Display/sf-ui-display-ultralight-58646b19bf205_0.otf');
}

@font-face {
  font-family: 'Display-Regular' ;
  src: url('./assets/Fonts/Display/SF\ UI\ Display\ Regular.ttf');
}


:root{
  --Color-Blanco: #fff;
  --Color-Negro: #000;
  --Color-Red-Primary: #E42B31;
  --Color-Grey: #EEEEEE;
  --Color-Grey-Dark:  #7F7F7F;
  --Color-Grey-Light: #F6F6F6;
  --Color-Green-Aproved: #41B96A;
  --Color-Yellow-Paused: #FFC120;

  --Font-Roboto-Regular: 'Roboto-Regular';
  --Font-Roboto-Thin: 'Roboto-Thin';
  --Font-Roboto-Black: 'Roboto-Bold';
  
  --Font-Display-Regular: 'Display-Regular';
  --Font-Display-UltraLight: 'Display-UltraLight';
  --Font-Display-Thin: 'Display-Thin';
  --Font-Display-SemiBold: 'Display-SemiBold';
  --Font-Display-Medium:'Display-Medium';
  --Font-Display-Light:'Display-Light';
  --Font-Display-Heavy: 'Display-Heavy';
  --Font-Display-Bold:'Display-Bold';
  --Font-Display-Black:'Display-Black'

}